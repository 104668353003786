.cart-item
 border-top: 1px solid #E5E5E5
 padding-top: 20px
 padding-bottom: 25px
 width: 100%
 position: relative
 display: flex
 @media all and (max-width: 991.8px)
  flex-direction: column
  align-items: center
 &:last-child
  border-bottom: 1px solid #E5E5E5
 &__col-check
  flex: 0 0 54px
  align-items: center
  justify-content: center
  display: flex
  @media all and (max-width: 991.8px)
   position: absolute
   left: 0
   top: 20px
 &__image
  flex: 0 0 150px
 &__col-info
  flex: 1 1 auto
  @media all and (max-width: 991.8px)
   width: 100%
 &__image-area
  padding: 17px 30px
  border: 1px solid #E5E5E5
  border-radius: 5px
  position: relative
  img
   object-fit: cover
 &__info
  padding-left: 24px
  @media all and (max-width: 991.8px)
   padding-left: 0
 &__col-image
  @media all and (max-width: 991.8px)
   margin-bottom: 20px

.cart-item-info
 &__name
  text-transform: uppercase
  color: #272727
  font: 700 21px/130% 'Open Sans', sans-serif
  margin-bottom: 10px
  display: block
  @media all and (max-width: 575.9px)
   font: 700 18px/130% 'Open Sans', sans-serif
 &__content
  display: flex
  @media all and (max-width: 991.8px)
   flex-direction: column
   align-items: center
 &__col
  flex: 1 1 50%
  @media all and (max-width: 991.8px)
   flex: 1 1 100%
   width: 100%
   .cart-price,.count
    margin-bottom: 15px
 &__footer
  padding: 3px 0
  display: flex
  @media all and (max-width: 991.8px)
   justify-content: center
 &__button-remove
  text-decoration-line: underline
  font: 400 15px/26px 'Open Sans', sans-serif
  color: #6D6F7C
  padding: 0
  &:hover
   color: #E30613
 &__count-block
  display: flex
  align-items: center
  justify-content: flex-end
  width: 100%
  .count
   margin-left: 20px
   margin-right: 20px
  @media all and (max-width: 991.8px)
   //flex-direction: column
   justify-content: center
   //& > *
   // margin-bottom: 10px

.cart-item-info__col--mr
 position: relative
 @media all and (max-width: 991.9px)
  margin-top: 15px
  flex-direction: column
.property-list
 display: flex
 flex-direction: column
 max-width: 270px
 width: 100%
 @media all and (max-width: 991.8px)
  max-width: 100%

.property-item
 position: relative
 padding: 7px 0
 display: flex
 justify-content: space-between
 &::after
  content: ''
  position: absolute
  width: 100%
  left: 0
  top: 50%
  border: 1px dashed #6D6F7C
  z-index: 1
 &__name
  padding: 0 10px 0 0
  position: relative
  z-index: 2
  background-color: #FFFFFF
  color: #6D6F7C
  font: 600 16px/130% 'Open Sans', sans-serif
 &__info
  padding: 0 0 0 10px
  position: relative
  z-index: 2
  background-color: #FFFFFF
  color: #0F1213
  font: 400 15px/130% 'Open Sans', sans-serif
  strong
   color: #076CB5
   font: 700 21px/23px "Open Sans", sans-serif
   font-style: italic

.cart-price
 white-space: nowrap
 .price, .rub

  text-transform: uppercase
  color: #272727
  font: 600 24px/26px 'Open Sans', sans-serif
 .rub
  font-weight: 400
  color: #6D6F7C
 .product-count
  color: #6D6F7C
  font: 400 18px/26px 'Open Sans', sans-serif

.cart-item
 .form-check-select-product
  top: -2px
  left: -2px
  position: relative
  @media all and (max-width: 991.8px)
   top: 0
   left: 0



