.contacts-form-container
 padding: 40px 0
 &__title
  color: #040404
  font-size: 21px

.contacts-form
 display: flex
 margin-top: 25px
 justify-content: space-between
 &__column
  width: calc(50% - 14px)
 &__form-group
  margin-bottom: 10px
 &__form-control
  padding: 9px
  height: 40px
  color: #040404
  font-size: 15px
  background: #EEF0F5
  border-radius: 3px
  border: none
  &::placeholder
   color: #606270
  &:focus
   background-color: #EEF0F5
 &__form-group-ext
  margin-top: 20px
  min-height: 40px
  display: flex
  justify-content: flex-end
  flex-direction: column
 &__ext
  color: #01509F
  font-size: 15px
  margin-bottom: 8px
 &__agreement
  color: #040404
  font-size: 15px
  label::selection
   background-color: transparent
   &:hover
    cursor: pointer
  a
   text-decoration: underline
   &::selection
    background-color: transparent
  input
   margin-left: 10px
 &__button-blue
  margin-top: 20px
  color: #F6F6F8
  width: 100%
  text-transform: uppercase
  font-size: 16px
  font-weight: 700
  padding: 12px

textarea.contacts-form__form-control
 height: 90px

.contacts-delivery
 background-color: #6D6F7C
 padding: 20px 0px
 p
  color: #fff
  font-size: 15px
  font-weight: 600
  padding: 0 45px
  line-height: 1.7
  position: relative
  z-index: 2
  @media all and (max-width: 991.8px)
   padding: 0
  @media all and (max-width: 575.8px)
   font-size: 14px
   font-weight: 500
   line-height: 1.3
  img
   position: absolute
   margin-top: -35px
   top: 50%
   left: -23px
   z-index: -1

.contacts-banner
 min-height: 455px
 position: relative
 padding-top: 120px
 @media all and (max-width: 575.8px)
  padding-top: 0
  min-height: 150px
 &__image
  top: 0
  left: 0
  position: absolute
  width: 100%
  height: 100%
  object-fit: cover
  object-position: center
 &__container
  position: relative
 &__title-block
  padding-top: 60px
 &__title
  margin: 0
 .title-bg
  height: 190px

