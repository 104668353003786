$indentX: 15px
$indentY: 10px
.table-product
 border: 1px solid #D2D2D2
 border-radius: 3px
 @media all and (max-width: 991.8px)
  border: none
 &__thead
  display: contents
  @media all and (max-width: 991.8px)
   display: none
 &__title
  vertical-align: middle
  text-align: center
  background: #076CB5
  padding: $indentY $indentX
  font: 700 15px/20px 'Open Sans', sans-serif
  text-transform: uppercase
  color: #fff
  border-right: 1px solid #fff
  th:last-child
   border: none
   @media all and (max-width: 991.8px)
    border-right: 1px solid #D2D2D2
    border-left: 1px solid #D2D2D2
 &__body
  @media all and (max-width: 991.8px)
   display: flex
   flex-wrap: wrap
  tr:nth-child(1n)
   background-color: #F6F6F6
  tr:nth-child(2n)
   background-color: #F3FBFF
  tr:nth-child(3n)
   background-color: #EFFAE4
  td
   vertical-align: middle
   text-align: center
   border-right: 1px solid #D2D2D2
   @media all and (max-width: 991.8px)
    //border-left: 1px solid #D2D2D2
    border-bottom: 1px solid #D2D2D2
    display: flex
    justify-content: center
    & > *
     flex: 0 0 50%
   span, strong
    font: 400 14px/110% 'Open Sans', sans-serif
    padding: 7px 15px
    display: flex
    justify-content: center
    align-items: center
   a
    text-decoration-line: underline
   strong
    font-weight: 700
  td:last-child
   border: none
   @media all and (max-width: 991.8px)
    border-right: 1px solid #D2D2D2
    border-left: 1px solid #D2D2D2
 &__tr
  border-bottom: 1px solid #D2D2D2
  @media all and (max-width: 991.8px)
   display: flex
   flex-direction: column
   flex: 0 1 50%
   margin-bottom: 10px
   border-top: 1px solid #D2D2D2
  @media all and (max-width: 767.8px)
   flex: 0 1 100%
  .table-product__title
   display: none
   border-right: 1px solid transparent
   border-left: 1px solid transparent
   @media all and (max-width: 991.8px)
    display: block
   @media all and (max-width: 424.8px)
    font: 700 12px/15px "Open Sans", sans-serif
    padding: 10px 5px
 &__tr:last-child
  border: none
  @media all and (max-width: 991.8px)
   border-bottom: 1px solid #D2D2D2
   border-top: 1px solid #D2D2D2

.button-table
 min-height: 22px
 border-radius: 3px
 background-color: #B6B9CB
 max-width: 100%
 width: 100%
 text-decoration-line: none!important
 padding: 3px
 span
  text-decoration-line: none
  color: #fff
  padding: 0 !important
  text-transform: uppercase
  font: 700 11px/15px 'Open Sans', sans-serif !important
 &:focus, &:focus-visible
  background-color: #B6B9CB
 &:active, &:hover
  background-color: #E30613

.table-product-block
 @media all and (max-width: 991.9px)
  padding-top: 0
