.model
 position: relative
 .product-info__title:before
  top: 3px
 .product__info-block
  padding-top: 40px
  padding-bottom: 40px
 .product-info__title
  color: #0F1213
  margin-bottom: 43px
  font: 700 30px/130% 'Open Sans', sans-serif
  padding-left: 30px
  @media all and (max-width: 991.8px)
   padding-left: 15px
 .product-info__params
  margin-left: 32px
  margin-right: 30px
  @media all and (max-width: 991.8px)
   margin-left: 15px
   margin-right: 15px
 .product-info__params .name-params span
  font: 600 21px/130% "Open Sans", sans-serif
  @media all and (max-width: 991.8px)
   font: 600 18px/20px "Open Sans", sans-serif

.model-delivery
 padding-left: 50px
 padding-right: 50px
 overflow: hidden
 @media all and (max-width: 767.8px)
  padding-left: 15px
  padding-right: 15px
  p
   padding: 0
   img
    margin-top: 0
    top: 0
    left: -20%

