.filter-select
 width: 100%
 padding: 0
 border: 1px solid #8C8C8C
 background-color: #fff
 border-radius: 3px
 display: flex
 justify-content: flex-start
 position: relative
 &__label
  white-space: nowrap
  padding: 0 10px
  flex: 1 1 auto
  text-align: left
  overflow: hidden
  text-overflow: ellipsis
  color: #000000
  font: 700 15px/130% 'Open Sans', sans-serif
  &--empty
   font-weight: 400
 &__icon-block
  border-left: 1px solid #8C8C8C
  width: 30px
  height: 30px
  display: flex
  align-items: center
  justify-content: center
  flex: 0 0 30px
 &__icon
  padding: 3px

.filter-select--empty
 background-color: #DCDFE5
 border-color: #A5A2A2
 cursor: not-allowed
 .filter-select__icon-block
  border-color: #A5A2A2
 .filter-select__icon
  opacity: 0.6

.filter-menu
 background-color: #fff
 border-radius: 4px
 margin-top: 8px
 position: absolute
 z-index: 2
 width: 100%
 .css-319lph-ValueContainer,
 .css-tlfecz-indicatorContainer,
 .css-6j8wv5-Input,
 .css-1gtu0rj-indicatorContainer
  margin: 0
  transition: 0.3s ease all
  padding: 0
 .css-319lph-ValueContainer
  display: flex
 .css-1cgrn4w-Control
  height: 40px
  padding: 15px 10px 6px 10px
  border-top-left-radius: 5px
  border-top-right-radius: 5px
  border-bottom: 1px solid #E5E5E5
 .css-14el2xx-placeholder
  margin: 0
 .css-b62m3t-container
  border-radius: 5px
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25)
 .css-26l3qy-menu
  padding: 2px 10px 10px 10px
  margin-top: 0
  box-shadow: none
  margin-bottom: 0
  position: relative
 .css-yr29xi-option
  padding: 5px 0
 .css-4ljt47-MenuList
  padding: 0
  padding-right: 10px
  & > div:last-child
   border-bottom: none
 .css-1okebmr-indicatorSeparator
  display: none
 .css-14el2xx-placeholder
  color: #000000
  font: 400 15px/20px 'Open Sans', sans-serif

.select-search-icon
 margin: 0
 transition: 0.3s ease all
 padding: 0

.filter-menu-blanket
 bottom: 0
 left: 0
 top: 0
 right: 0
 position: fixed
 z-index: 1

.filter-menu-dropdown
 position: relative

