$pb: 45px
.slider-models
 position: relative
 .swiper
  padding-bottom: $pb
 .prev, .next
  //background: red
  top: calc(50% - #{$pb})
  position: absolute
  z-index: 10
  width: 26px
  height: 52px
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  cursor: pointer
 .prev
  background-image: url("../../assets/icons/arrow.svg")
  left: -50px
 .next
  background-image: url("../../assets/icons/arrow.svg")
  transform: scale(-1, 1)
  right: -50px
 .swiper-pagination-bullet
  width: 28px
  height: 6px
  background: #BDBDBD
  border-radius: 3px
 .swiper-pagination-bullet-active
  background: #FFFFFF
 .next.swiper-button-disabled
  pointer-events: none
  transform: scale(1, 1)
  background-image: url("../../assets/icons/arrow-disable.svg")
 .prev.swiper-button-disabled
  pointer-events: none
  transform: scale(-1, 1)
  background-image: url("../../assets/icons/arrow-disable.svg")

.slider-certificates
 margin-top: 40px
 .swiper-slide
  //transition: 0.2s ease all!important
  max-width: 446px
  width: 100%
  //height: 642px
  img
   max-width: 100%
 .swiper-wrapper
  align-items: center
 .swiper-pagination-bullet
  background: #BDBDBD
 .swiper-pagination-bullet-active
  background: #6D6F7C
 .prev
  transform: scale(-1, 1)
  background-image: url("../../assets/icons/arrow-disable.svg")
  @media (max-width: 767px)
   display: none
 .next
  transform: scale(1, 1)
  background-image: url("../../assets/icons/arrow-disable.svg")
  @media (max-width: 767px)
   display: none
 .prev.swiper-button-disabled,
 .next.swiper-button-disabled
  opacity: 0.5
 .swiper-3d .swiper-slide-shadow-left,
 .swiper-3d .swiper-slide-shadow-right
  background-image: linear-gradient(to left, rgba(0, 0, 0, .05), rgba(0, 0, 0, 0))

.slider-product-area
 margin-bottom: 30px
 .product-card
  user-select: none

.slider-main
 z-index: 0
 position: absolute
 width: 100%
 height: 100%
 left: 0
 top: 0
 .swiper
  height: inherit
 .swiper-pagination
  bottom: 70px !important
 .swiper-pagination-bullet
  opacity: 1
  background-color: #D5D6DA
 .swiper-pagination-bullet-active
  background-color: #fff
 &__container
  position: relative
  z-index: 1


