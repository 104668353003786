.footer-area
 background-color: #1A2939

.footer
 padding-top: 33px
 padding-bottom: 78px
 @media all and (max-width: 575.9px)
  padding-bottom: 33px
 &__navigation
  margin: 0 0 38px 0
 &__info
  text-align: center
  font: 400 15px/20px 'Open Sans', sans-serif
  color: #FFFFFF
  margin-bottom: 39px
  @media all and (max-width: 575.9px)
   font: 400 13px/18px 'Open Sans', sans-serif

.footer-block
 position: relative
 display: flex
 flex-direction: column
 @media all and (max-width: 991.9px)
  margin-bottom: 20px
 @media all and (max-width: 575.9px)
  width: 100%
 &__middle
  top: 30px
  @media all and (max-width: 991.9px)
   top: auto
 &__fix
  min-height: 170px
  margin-left: auto
  @media all and (max-width: 991.9px)
   margin-left: inherit
 &__button-white
  max-width: 250px
  width: 100%
  font: 700 16px/22px 'Open Sans', sans-serif
  text-transform: uppercase
  padding: 10px
  margin: 0 auto
 &__phone
  text-align: center
  margin: 49px 0 33px 0
  color: #B0B6C6
  font: 400 28px/38px 'Open Sans', sans-serif
  @media all and (max-width: 991.9px)
   margin: 20px 0
  @media all and (max-width: 575.9px)
   font: 400 20px/32px 'Open Sans', sans-serif
  strong
   font-weight: 700
   color: #fff
  &:active, &:hover, &:focus, &:focus-visible
   color: #B0B6C6
   strong
    color: #fff
 &__location
  margin-bottom: 11px
  display: flex
  align-items: baseline
  p
   color: #FFFFFF
   font: 700 18px/25px 'Open Sans', sans-serif
 &__icon
  margin-right: 10px
 &__contact
  display: flex
  align-items: end
  margin-bottom: 20px
  a
   color: #F6F6F8
   font: 400 13px/18px 'Open Sans', sans-serif
   strong
    font-weight: bold
 &__logo
  display: flex
  justify-content: center
  img
   width: 375px
   height: auto
   @media all and (max-width: 575.9px)
    width: 200px
 &__text
  color: #D8D8D8
  margin-top: 40px
  font: 400 13px/18px 'Open Sans', sans-serif
  &:active, &:hover, &:focus, &:focus-visible
   color: #D8D8D8
  @media all and (max-width: 991.9px)
   margin-top: 0
  @media all and (max-width: 575.9px)
   text-align: center

.footer-line
 background: #EEF0F5
 padding-top: 18px
 padding-bottom: 18px
 &__text
  text-align: center
  color: #272727
  font: 400 11px/15px "Open Sans", sans-serif

.footer-navigation
 display: flex
 justify-content: space-between
 &__item
  flex: 0 1 auto
  display: flex
 &__link
  font: 400 15px/20px "Open Sans", sans-serif
  color: #FFFFFF
  position: relative
  text-transform: uppercase
  &--active
   font-weight: 700
   &::after
    opacity: 1
  &::after
   content: ''
   position: absolute
   bottom: -5px
   left: 0
   width: 100%
   height: 3px
   background-color: #076CB5
   opacity: 0
   transition: 0.2s ease opacity
  &:active, &:hover, &:focus, &:focus-visible
   color: #FFFFFF
   &::after
    opacity: 1


