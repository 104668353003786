.category-title-block
 margin-bottom: 30px
 background-color: #EEF0F5
 border-radius: 3px
 padding: 6px 20px
 &__title
  color: #01509F
  font: 700 18px/130% 'Open Sans', sans-serif

.catalog-filter-area
 user-select: none
 background-color: #EEF0F5
 border-radius: 3px
 padding: 10px 0 65px 0
 &__button
  color: #1E2225
  text-transform: uppercase
  border: 1px solid #6D6F7C
  font: 700 15px/20px 'Open Sans', sans-serif
  min-height: 31px
  width: 100%
  &:active, &:hover, &:focus, &:focus-visible
   background-color: #E30613
   border-color: #E30613
   span
    color: #fff

.filter
 position: relative
 &--hide
  display: none
 &__header
  position: relative
 &__content
  background-color: #EEF0F5
  padding: 15px 20px 20px 20px
 &__line
  width: 100%
  height: 1px
  background-color: #8C8C8C
  margin: 0

.filter-type
 border: 1px solid #D2D3D7
 border-radius: 5px
 background-color: #fff
 overflow: hidden
 margin-bottom: 20px
 &__content
  position: relative
  display: flex
 &__button:first-child
  border-left: none
 &__button
  flex: 1 1 auto
  display: flex
  position: relative
  background-color: #fff
  padding: 0px 10px
  border-left: 1px solid #D2D3D7
  transition: 0.2s ease all
  &--active
   background-color: #6D6F7C
   .filter-type__button-text
    color: #fff
    font-weight: 700
    &::before
     display: block
 &__button-text
  padding: 10px 0
  position: relative
  text-transform: uppercase
  color: #1E2225
  font: 400 14px/100% 'Open Sans', sans-serif
  &::before
   display: none
   position: absolute
   height: 3px
   content: ''
   left: 0
   bottom: 0
   width: 100%
   background-color: #E30613

.filter-header
 position: relative
 background-color: #6D6F7C
 padding: 4px 5px 4px 20px
 &::before
  content: ''
  position: absolute
  background-image: url("../icons/filter-border.svg")
  background-repeat: no-repeat
  width: 100%
  height: 100%
  left: 0
  top: 0
  bottom: 0
 &__title-block
  position: relative
 &__title
  margin-right: 10px
  display: inline-block
  color: #FFFFFF
  font: 700 18px/130% 'Open Sans', sans-serif
  span
   font-weight: 400
 &__button-reset
  position: relative
  color: #FFFFFF
  padding: 0
  border: none
  display: inline-block
  font: 400 15px/20px 'Open Sans', sans-serif
  &:before
   content: ''
   width: 100%
   position: absolute
   border: 1px dashed #fff
   bottom: -1px
   left: 0

.filter-reset
 padding: 2px 5px
 margin: 0 auto
 span
  text-decoration-line: underline
  color: #E30613
  font: 400 15px/17px 'Open Sans', sans-serif

.filter-apply
 padding: 2px 5px
 margin: 0 auto
 span
  text-decoration-line: underline
  font: 400 15px/17px 'Open Sans', sans-serif

.filter-button
 margin-bottom: 15px
 //max-width: 100px
 width: 100%
 background: #EEF0F5
 border-radius: 5px
 min-height: 43px
 span
  color: #1A2B3E
  //text-transform: uppercase
  font: 600 21px/29px "Open Sans", sans-serif
 img
  margin: 0 5px
 .count
  font: 800 13px/15px "Open Sans", sans-serif
  color: #fff
  width: 21px
  height: 21px
  border-radius: 50%
  background-color: #E30613
  padding: 0
  display: flex
  align-items: center
  justify-content: center

.filter-content
 &__input-container
  margin-bottom: 20px

.form-check-filter
 margin-bottom: 15px
 position: relative
 font: 300 15px/19.5px 'Open Sans', sans-serif
 color: #1A1A1D
 input:checked ~ .checkbox
  background-color: #076cb5
 .form-check-input
  margin-right: 8px
  top: 1px
  position: relative
  background-color: #CDCFD5
  &[type="checkbox"]
   border-color: #CDCFD5
   border-radius: 0
   background-image: none
  &:checked
   background-color: #076CB5

.range-price
 margin-bottom: 20px
 .rc-slider-rail
  background-color: #7A7C87
 .rc-slider-track
  background-color: #076CB5
 .rc-slider-handle
  outline-width:  20px
  outline-color: red
  border-color: #076CB5 !important
  opacity: 1
  background-color: #076CB5
  @media all and (max-width: 575.9px)
   width: 16px
   height: 16px
   margin-top: -7px
  &:active
   border-color: #076CB5
   box-shadow: none
  &:hover
   border-color: #076CB5
 .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging
  border-color: #076CB5
  box-shadow: none

.filter-range-price-inputs
 display: flex
 margin-bottom: 20px
 justify-content: space-between
 input
  height: 35px
  width: 80px
  border: 1px solid #8C8C8C
  text-align: center
  background-color: #fff
  font: 700 15px/130% 'Open Sans', sans-serif
  border-radius: 3px

.area
 top: 5px
 margin-bottom: 10px
 @media all and (max-width: 991.9px)
  top: auto
  margin-bottom: 0

.catalog-view-type-sort,
.catalog-limit
 margin-bottom: 15px
//@media all and (max-width: 991.9px)
// margin-bottom: 15px

.catalog-view-type-sort,
.catalog-limit
 margin-right: 15px
 display: flex
 flex-wrap: wrap
 span, &__item
  padding: 0
  color: #6D6F7C
  margin-right: 10px
  position: relative
  font: 400 15px/26px 'Open Sans', sans-serif
 &__item
  transition: 0.2s ease all
 &__item::after
  content: ''
  width: 100%
  height: 2px
  border-bottom: 1px dashed #6D6F7C
  position: absolute
  bottom: -2px
  left: 0
 &__item:last-of-type
  margin-right: 0

//.catalog-view-type-sort__item:focus
.catalog-view-type-sort__item--active,
.catalog-view-type-sort__item:hover,
.catalog-view-type-sort__item:active
 color: #01509F
 font-weight: 700
 &::after
  border-color: #076CB5

.catalog-limit
 align-items: center
 margin-right: 0
 display: flex
 &__item, span
  position: relative
  color: #60656E
  font-size: 13px
  margin-right: 15px
 span
  margin-right: 5px
 &__item
  &:last-child
   margin-right: 0
   &::before
    display: none
  &::before
   content: ''
   position: absolute
   right: -9px
   top: 7px
   width: 1px
   height: 13px
   background-color: #60656E
  &::after
   display: none
  &--active
   font-weight: 700
   color: #E30613
  &:hover, &:active, &:focus
   font-weight: 700
   color: #E30613

.bubble-area
 margin-bottom: 15px
 position: relative
 border: 2px solid #E5E5E5
 border-left: none
 border-right: none
 padding-bottom: 0px
 @media all and (max-width: 991.9px)
  padding-bottom: 5px

.bubble-list
 display: flex
 flex-wrap: wrap
 align-items: center
 padding-top: 10px
 max-width: 100%
 @media all and (max-width: 991.9px)
  overflow-x: auto
  flex-wrap: nowrap

.bubble
 cursor: pointer
 width: max-content
 background: #EEF0F5
 padding: 7px 5px 7px 25px
 border-radius: 30px
 display: flex
 align-items: center
 margin: 0 5px 10px 0
 @media all and (max-width: 991.9px)
  margin-bottom: 5px
 &:last-child
  margin-right: 0
 &:hover
  .bubble__button
   opacity: 1
 span
  white-space: nowrap
  color: #6D6F7C
  text-transform: uppercase
  font: 600 11px/14px 'Open Sans', sans-serif
 &__button
  flex: 0 0 12px
  width: 12px
  opacity: 0
  padding: 0
  margin-left: 9px
  @media all and (max-width: 991.9px)
   opacity: 1
