.mobile-filter
 user-select: none
 width: 100%
 position: fixed
 top: 0
 left: -110%
 height: 100vh
 z-index: 999
 background-color: #EEF0F5
 color: #fff
 transition: all 0.3s
 box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2)
 flex-direction: column
 overflow: auto
 padding: 15px 0
 &.active
  left: 0
