.contact-map
  height: 560px
  position: relative
  @media all and (max-width: 767.8px)
    height: auto
  &__content
    position: absolute
    z-index: 99
    top: 0
    left: 0
    width: 100%
    height: 100%
    padding-top: 60px
    pointer-events: none
  &__addresses
    border-radius: 5px
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15)
    padding: 17px 5px 17px 30px
    background-color: #fff
    pointer-events: auto
    max-width: 290px
    height: 400px
    overflow: hidden
  &__addresses-scroll
    overflow: auto
    max-height: 100%
    padding-right: 20px
    
  &__addresses-title
    color: #E30613
    font-size: 23px
    font-weight: 700
    font-style: italic
    line-height: 130%
  &__link
    color: #E30613
    text-decoration: underline
    white-space: nowrap
    cursor: pointer
  &__button-blue
    text-transform: uppercase
    font: bold 16px/22px "Open Sans", sans-serif
    transform: skewX(-13deg)
    font-size: 16px
    padding: 10px 0px
    margin-top: 30px
    span
      transform: skewX(13deg)
  &__list
    list-style-type: none
    margin-top: 8px

.contact-map-item
  &__location
    color: #0E0E0F
    text-transform: uppercase
    font: bold 15px/150% "Open Sans", sans-serif
    margin-bottom: 7px
  &__contacts
    margin-bottom: 10px
    display: flex
    flex-direction: column
  &__contact
    margin-bottom: 7px
    &:last-child
      margin-bottom: 0
  &__contact-icon
    margin-right: 5px
    width: 13px
    height: 13px
    flex: 0 0 13px
  &__contact-phone
    text-decoration-line: underline
    font: 400 13px/18px "Open Sans", sans-serif
    strong
      font-weight: 700
  &__contact-email
    text-decoration-line: underline
    font: 400 13px/18px "Open Sans", sans-serif

.contact-map-region
  margin-bottom: 10px
  &__name
    position: relative
    padding-left: 18px
    color: #01509F
    margin-bottom: 4px
    font: 600 15px/150% "Open Sans", sans-serif
    &:after
      content: ''
      position: absolute
      width: 13px
      height: 8px
      border-radius: 2px
      background-color: #076CB5
      left: 0
      top: 50%
      transform: translateY(-50%)
  &__list
    position: relative
  &__item
    padding-left: 18px
    text-decoration-line: underline
    font: 400 15px/19px "Open Sans", sans-serif
    color: #040404
    position: relative
    &:before
      content: ''
      position: absolute
      width: 11px
      height: 15px
      background-image: url("../../assets/icons/icon-6.svg")
      background-repeat: no-repeat
      background-position: center
      left: 0
    &:hover,&:active,&:focus
      color: #E30613
      cursor: pointer
      
.contact-map-line
  width: 100%
  height: 1px
  margin: 15px 0
  background-color: #BDBDBD

.map
  height: 560px
  @media all and (max-width: 575.9px)
    height: 300px

@media all and (max-width: 767.9px)
  .contact-map__content
    position: relative
    height: auto
    padding-top: 0
    .row > *
      padding: 0
    .contact-map__addresses
      box-shadow: none
      max-width: 100%
