$px: 20px
.col-product-card
 margin-bottom: 30px

.product-card
 background-color: #fff
 box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15)
 border-radius: 3px
 flex-direction: column
 display: flex
 height: 100%
 transition: 0.2s ease all
 @media all and (max-width: 767.8px)
  margin: 0 20px
 &__image-block
  //height: 230px
  position: relative
  width: inherit
  padding: 15px $px
  display: inline-block
  align-items: center
  //display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */
  //display: -moz-box    /* OLD - Firefox 19- (buggy but mostly works) */
  //display: -ms-flexbox  /* TWEENER - IE 10 */
  //display: -webkit-flex /* NEW - Chrome */
  justify-content: center
  img
   width: inherit
   display: block
   margin: 0 auto
   max-width: 100%
 &__info-block
  padding: 0 $px 10px $px
  height: 100%
  display: flex
  flex-direction: column
 &__name
  font: 400 15px/150% 'Open Sans', sans-serif
  text-decoration-line: underline
  margin-bottom: 10px
  width: 100%
  display: inline-block
 &__articul
  margin-top: auto
  display: flex
  justify-content: space-between
  position: relative
  margin-bottom: 10px
  &::after
   content: ''
   position: absolute
   width: 100%
   top: 50%
   z-index: 1
   border-bottom: 1px dashed #7E808B
  .articul
   padding-right: 10px
   z-index: 2
   background-color: #fff
   font: 400 14px/19px 'Open Sans', sans-serif
  .articul-number
   padding-left: 10px
   z-index: 2
   background-color: #fff
   font: 400 13px/18px 'Open Sans', sans-serif
 &__prices
  display: flex
  align-items: center
 &__price
  margin-right: 10px
  .price
   font: 600 18px/24px 'Open Sans', sans-serif
  .rub
   font: 600 18px/24px 'Open Sans', sans-serif
 &__old-price
  color: #7E808B
  position: relative
  &::after
   content: ''
   position: absolute
   width: 100%
   top: 50%
   z-index: 1
   height: 1px
   background-color: #E30613
   left: 0
  .price
   font: 400 14px/19px 'Open Sans', sans-serif
  .rub
   font: 600 14px/19px 'Open Sans', sans-serif
 &__button-block
  padding: $px
  margin-top: auto
  background: #D2D3D7
  border-radius: 3px
 &__button-blue
  min-height: 31px
  max-width: 100%
  width: 100%
  padding: 5px
  span
   text-transform: uppercase
   font: bold 16px/22px 'Open Sans', sans-serif

.model-card
 position: relative
 &__name
  text-align: center
  text-transform: uppercase
  text-decoration-line: underline
  color: #1E2225
  font: 600 24px/150% 'Open Sans', sans-serif
  margin-bottom: 5px
 &__info
  text-align: center
  color: #040404
  font: 600 17px/130% 'Open Sans', sans-serif
 &__link
  text-align: center
  text-decoration-line: underline
  color: #E30613
  display: block
  text-transform: uppercase
  font: 600 17px/26px 'Open Sans', sans-serif

.is--showed
 animation: bubble-show .2s ease-in-out
 transition: all ease-in-out .5s

@keyframes bubble-show
 0%
  opacity: 0
  transform: scaleX(0)
 50%
  transform: scaleX(1)
 100%
  opacity: 1
