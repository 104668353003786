$h: 77px
.choose-tyre
 //margin-top: 140px
 //height: $h
 display: flex
 align-items: center
 position: relative
 width: 100%
 flex-wrap: wrap
 @media (max-width: 991.9px)
  flex-direction: column
  padding: 0 5px
  margin-top: 235px
 @media (max-width: 767.9px)
  padding: 0
  margin-top: 120px
 @media (max-width: 575.9px)
  margin-top: 220px
  //padding: 0
 &__block
  flex: 1 1 auto
  flex-wrap: wrap
  border-radius: 5px 0 0 5px
  background-color: #FFFFFF
  display: flex
  @media (max-width: 991.9px)
   width: 100%
   flex: 1 1 auto
   border-radius: 5px 5px 0 0
  @media (max-width: 767.9px)
   flex-direction: column
 &__input-block:last-child::before
  display: none
 &__input-block
  flex: 1 0 25%
  //z-index: 1
  position: relative
  height: $h
  //width: 235px
  display: flex
  align-items: center
  transition: 0.2s ease all
  @media (max-width: 767.9px)
   padding: 0 3px
   flex: 1 1 auto
  @media all and (max-width: 767.9px)
   height: 55px
  &::before
   top: 10px
   right: 0
   position: absolute
   content: ''
   width: 1px
   height: 57px
   background-color: #858798
   z-index: 1
   @media (max-width: 767.9px)
    width: 100%
    height: 1px
    right: 0
    left: 50%
    transform: translateX(-50%)
    bottom: 0
    top: auto
  &--disable
   cursor: not-allowed
   //opacity: 0.5
   pointer-events: none
   //background-color: #DCDFE5
   //.choose-select__control
   //  background-color: #DCDFE5
   //.choose-select__input-container
   //  cursor: not-allowed!important
   .choose-select__single-value
    color: #D4D4D4 !important
   .choose-select__indicator img
    opacity: 0.25
 &__button
  height: $h
  flex: 0 0 185px
  border-radius: 0 5px 5px 0
  @media (max-width: 991.9px)
   flex: 1 1 auto
   width: 100%
   border-radius: 0 0 5px 5px
  @media all and (max-width: 767.9px)
   height: 55px
  span
   font-weight: 700
   text-transform: uppercase

.choose-container
 width: 100%

.choose-select
 position: relative
 &__control
  padding: 0px 25px 0px 24px
  width: 100%
  border: none !important
  box-shadow: none !important
  @media (max-width: 767.9px)
   padding: 0px 10px 0px 10px
  &:hover
   box-shadow: none !important
 &__input-container
  position: relative
  cursor: pointer !important
 &__placeholder
  color: #1E2225 !important
  font: 400 16px/22px 'Open Sans', sans-serif !important
 &__indicator-separator
  display: none
 &__single-value
  cursor: pointer
  color: #1E2225 !important
  font: 600 16px/22px 'Open Sans', sans-serif
 &__menu
  box-shadow: 0px 35px 84px rgba(0, 0, 0, 0.21) !important
  //padding-right: 3px!important
  background-color: #fff !important
  top: 150% !important
  @media all and (max-width: 767.8px)
   top: 110%!important
 &__menu-list
  padding: 0 !important
  border-radius: 3px !important
 &__option:last-child::before
  display: none
 &__option
  cursor: pointer !important
  background-color: #fff !important
  padding: 12px 12px !important
  position: relative
  &::before
   content: ''
   position: absolute
   left: 0
   bottom: 0
   z-index: 1
   width: 98.5%
   border-bottom: 1px solid #D6D6D6 !important
 &__option--is-focused, &__option--is-selected
  font-weight: 700 !important
  color: #fff !important
  background-color: #474B4F !important


