$px: 30px
.drop-cart
 width: 786px
 background: #FFFFFF
 border: 1px solid #DBDBDB
 padding-top: 24px
 @media all and (max-width: 767.9px)
  width: 100%
 &__header
  justify-content: space-between
  display: flex
  align-items: center
  margin-bottom: 25px
  padding-left: $px
  padding-right: $px
  @media all and (max-width: 575.9px)
   padding-left: $px / 2
   padding-right: $px /2
   .button
    padding: 20px 0 20px  20px
 &__title
  color: #01509F
  font: 600 21px/130% 'Open Sans', sans-serif
 &__content
  padding-left: $px
  padding-right: $px
  @media all and (max-width: 575.9px)
   padding-left: $px / 2
   padding-right: $px /2
 &__footer
  padding-left: $px
  padding-right: $px
  background: #F1F1F1
  padding-top: 12px
  padding-bottom: 12px
  display: flex
  //flex-wrap: nowrap
  justify-content: space-between
  @media all and (max-width: 767.9px)
   flex-direction: column
  @media all and (max-width: 575.9px)
   padding-left: $px / 2
   padding-right: $px /2
 &__buttons
  display: flex
  align-items: center
  justify-content: flex-end
  flex: 1 1 auto
  @media all and (max-width: 767.9px)
   justify-content: center
  @media all and (max-width: 575.9px)
   flex-wrap: wrap
 &__button
  margin: 5px 0 5px 5px
  padding: 12px 20px
  max-width: 249px
  width: 100%
  //margin-left: 5px
  @media all and (max-width: 575.9px)
   max-width: 100%
  span
   font: bold 14px/19px 'Open Sans', sans-serif

.cart-product-mini
 display: flex
 align-items: center
 margin-bottom: 20px
 @media all and (max-width: 575.9px)
  flex-direction: column
  justify-content: center
 &__image
  width: 100px
  margin-right: 28px
  flex: 0 0 100px
  height: 100px
  display: flex
  justify-content: center
  align-items: center
  border: 1px solid #D6D6D6
  position: relative
  padding: 5px
  border-radius: 3px
  @media all and (max-width: 575.9px)
   margin-right: 0
  img
   max-width: 100%
   height: 100%
 &__name
  margin-right: 40px
  @media all and (max-width: 575.9px)
   text-align: center
   margin: 10px 0
  span
   font: 600 18px/150% 'Open Sans', sans-serif
 &__total-price
  white-space: nowrap
  display: flex
  flex-direction: column
 &__price
  position: relative
  //margin-bottom: 8px
 &__old-price
  margin-top: 8px
  text-align: center
  text-decoration-line: line-through
 .count
  margin-right: 40px
  @media all and (max-width: 575.9px)
   margin-right: 0
   margin-bottom: 10px

.cart-mini-price
 display: inline-block
 margin-right: 3px
 color: #E30613
 font: bold 21px/29px 'Open Sans', sans-serif

.cart-mini-rub
 display: inline-block
 color: #7E808B
 font: 300 21px/29px 'Open Sans', sans-serif

.cart-mini-old-price
 color: #7E808B
 font: 400 14px/19px 'Open Sans', sans-serif

.cart-mini-old-rub
 color: #7E808B
 font: 600 14px/19px 'Open Sans', sans-serif

.drop-cart-info
 margin: 5px 0
 text-transform: uppercase
 display: flex
 align-items: center
 @media all and (max-width: 575.9px)
  justify-content: center
 &__count
  text-transform: uppercase
  color: #7E808B
  width: 50px
  font: 600 13px/150% 'Open Sans', sans-serif
  text-decoration-line: none
  margin-right: 20px
 &__price
  text-transform: uppercase
  position: relative
  color: #7E808B
  font: 300 21px/29px 'Open Sans', sans-serif
  span
   color: #E30613
   font: 700 23px/31px 'Open Sans', sans-serif
