#sidebar
 user-select: none
 width: 320px
 position: fixed
 top: 0
 left: -110%
 height: 100vh
 z-index: 999
 background-color: #252735
 color: #fff
 transition: all 0.3s
 //display: none
 box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2)
 flex-direction: column
 padding: 0 .9375rem
 overflow: visible
 &.active
  left: 0

#dismiss
 z-index: 10
 width: 20px
 height: 20px
 line-height: 35px
 text-align: center
 position: absolute
 top: 10px
 right: 10px
 display: flex
 align-items: center
 justify-content: center
 cursor: pointer
 -webkit-transition: all 0.3s
 -o-transition: all 0.3s
 transition: all 0.3s
 img
  display: block
//&:hover
//  background: #fff
//  color: #7386D5

.overlay
 display: none
 position: fixed
 width: 100vw
 height: 100vh
 background: rgba(0, 0, 0, 0.7)
 z-index: 998
 opacity: 0
 transition: all 0.5s ease-in-out
 @media all and (max-width: 991.8px)
  &.active
   display: block
   opacity: 1

.sidebar
 &__logo-block
  padding: 20px 20px 20px 0
  margin-bottom: 20px
  img
   max-width: 100%

.accordion-button
 inset: auto

.mobile-nav
 overflow-x: hidden
 background-color: #252735
 position: relative
 overflow-y: auto
 max-height: 100%
 .accordion-collapse
  background-color: #252735
 .mobile-nav__text
  width: 100%
  transition: 0.3s ease opacity
  &--active
   position: relative
   padding-left: 8px
   &:before
    position: absolute
    left: 0px
    top: 0
    content: ''
    height: 100%
    width: 4px
    background-color: #E30613

 .accordion-button
  padding: 0
  background-color: transparent
  color: #fff
  &:focus
   border: none
   box-shadow: none
  &:after
   display: none
 &__header
  margin-bottom: 0px
  font: bold 15px/20px "Open Sans", sans-serif
  .header-main__icon-arrow
   right: 0px
   top: 5px
  [aria-expanded=true]
   .header-main__icon-arrow
    transform: rotate(180deg)
 &__body
  display: flex
  background-color: #252735
  flex-direction: column
  padding: 0
 &__item
  margin-bottom: 20px
  border: none
  background-color: #252735
 &__link
  display: inline-block
  width: 100%
  padding: 10px 0
  color: #fff
  margin-bottom: 0px
  &:last-child
   margin-bottom: 0
 &__body-item
  background-color: #252735
  padding: 10px 15px 0px 15px
