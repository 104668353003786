.breadcrumbs-area
  border-bottom: 1px solid #D8D8D8
  background-color: #fff
  &__container
    padding-top: 10px
    padding-bottom: 10px
    //padding-left: 0
    //padding-right: 0

.breadcrumbs
  display: flex
  flex-wrap: wrap
  align-items: center
  margin: 0 0 0 2px
  &__item
    position: relative
    margin-right: 20px
    &::after
      background-color: #1A2939
      content: ''
      position: absolute
      right: -10px
      top: 6px
      height: 14px
      width: 1px
      transform: rotate(15deg)
  &__link
    cursor: pointer
    position: relative
    text-transform: uppercase
    color: #1A2939
    font: 400 13px/26px 'Open Sans', sans-serif
  &__item:last-child
    &::after
      display: none
    .breadcrumbs__link
      cursor: default
      font-weight: 700
      color: #E30613
      text-decoration-line: underline
