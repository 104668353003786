button
 -webkit-appearance: none
 -moz-appearance: none
 appearance: none
 user-select: none
 touch-action: manipulation

.button
 display: flex
 align-items: center
 justify-content: center
 background-color: transparent
 outline: none
 border: none
 padding: 5px
 margin: 0
 transition: 0.3s ease-in-out all
 &:disabled
  cursor: not-allowed
  background-color: #EEF0F5
  &:active, &:hover
   background-color: #EEF0F5
 &--skew
  border-radius: 3px
  transform: skewX(-15deg)
  span
   transition: 0.3s ease-in-out all
   white-space: nowrap
   text-transform: uppercase
   transform: skewX(15deg)
 &--red
  background-color: #E30613
  span
   color: #fff
  &:focus
   background-color: #E30613
  &:active, &:hover
   background-color: #CD000C
 &--gray
  background-color: #6D6F7C
  span
   color: #fff
  &:active, &:hover, &:focus, &:focus-visible
   background-color: #616370
 &--blue
  background: #076CB5
  border-radius: 3px
  span
   color: #fff
  &:focus
   background-color: #076CB5
  &:active, &:hover
   background-color: #E30613
 &--white
  border-radius: 3px
  background: #FFFFFF
  span
   color: #272727
  &:focus
   background-color: #FFFFFF
  &:active, &:hover
   background-color: #076CB5
   span
    color: #fff
